import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { LayoutUtilsService } from '../core/_base/crud';

@Injectable({
  providedIn: 'root'
})
export class TestsGuard implements CanActivate {
  constructor(private LayoutUtilsService: LayoutUtilsService, private router: Router) {}

  checkAuth(): boolean {
    const currentUser = JSON.parse(
      localStorage.getItem('x-current-user')
    );
    const { role } = currentUser.auth
      ? currentUser.auth
      : currentUser;
    if (role && role.tests) {
      return true;
    }
    this.LayoutUtilsService.showActionNotification(`Access Denied`);
    this.router.navigate(['/not-found']);
    return false;
  }

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkAuth();
  }
}
